var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"channelHistory"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t('fastChannels')))]),_c('h5',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t('historyChannel'))+" ")])]),_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticStyle:{"height":"fit-content"},attrs:{"variant":"danger"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" "+_vm._s(_vm.$t("dataGeneric.goBack"))+" ")])],1)],1),_c('b-overlay',{attrs:{"show":_vm.show,"variant":"transparent","opacity":1.00,"blur":'2px',"rounded":"lg"}},[_c('b-table',{attrs:{"items":_vm.exportations,"fields":[
        _vm.isSuperuser ? { key: 'id', label: 'Batch ID' } : '',
        { key: 'user', label: _vm.$t('User') },
        { key: 'createDate', label: _vm.$t('subscriptions.createdAt') },
        { key: 'filesCount', label: _vm.$t('filesCount') },
        { key: 'successFiles', label: _vm.$t('successFiles') },
        { key: 'failedFiles', label: _vm.$t('failedFiles') },
        { key: 'actions', label: _vm.$t('resources.tableHeader.actions') } ],"responsive":"sm","show-empty":"","striped":"","empty-text":_vm.$t('emptyText')},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v(_vm._s(_vm.$t('load'))+"...")])],1)]},proxy:true},{key:"cell(user)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.isEmpty(data.item.user) ? '' : data.item.user.username)+" ")])]}},{key:"cell(createDate)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.formateDateTime(data.item.createDate))+" ")])]}},{key:"cell(filesCount)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.filesCount)+" ")])]}},{key:"cell(successFiles)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.successFiles)+" ")])]}},{key:"cell(failedFiles)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.failedFiles)+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('b-button',{attrs:{"variant":"outline-success","size":"sm"},on:{"click":function($event){return _vm.channelExportations(data.item)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1)],1)]}}])}),(_vm.hasMore)?_c('div',{staticClass:"d-flex justify-content-center align-self-center mt-2"},[_c('small',[_vm._v(_vm._s(_vm.$t('downToSeeEl')))]),_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"ArrowDownIcon","size":"20"}})],1):_vm._e()],1),_c('div',{ref:"infiniteScroll"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }