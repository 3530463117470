<template>
  <section id="channelHistory">
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t('fastChannels') }}</h1>
        <h5 class="text-primary">
          {{ $t('historyChannel') }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          style="height: fit-content;"
          variant="danger"
          @click="goBack()"
        >
          {{
            $t("dataGeneric.goBack")
          }}
        </b-button>
      </b-col>
    </b-row>
    <b-overlay
      :show="show"
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-table
        :items="exportations"
        :fields="[
          isSuperuser ? { key: 'id', label: 'Batch ID' } : '',
          { key: 'user', label: $t('User') },
          { key: 'createDate', label: $t('subscriptions.createdAt') },
          { key: 'filesCount', label: $t('filesCount') },
          { key: 'successFiles', label: $t('successFiles') },
          { key: 'failedFiles', label: $t('failedFiles') },
          { key: 'actions', label: $t('resources.tableHeader.actions') },
        ]"
        responsive="sm"
        show-empty
        striped
        :empty-text="$t('emptyText')"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>{{ $t('load') }}...</strong>
          </div>
        </template>
        <template #cell(user)="data">
          <div class="text-nowrap">
            {{ isEmpty(data.item.user) ? '' : data.item.user.username }}
          </div>
        </template>
        <template #cell(createDate)="data">
          <div class="text-nowrap">
            {{ formateDateTime(data.item.createDate) }}
          </div>
        </template>
        <template #cell(filesCount)="data">
          <div class="text-nowrap">
            {{ data.item.filesCount }}
          </div>
        </template>
        <template #cell(successFiles)="data">
          <div class="text-nowrap">
            {{ data.item.successFiles }}
          </div>
        </template>
        <template #cell(failedFiles)="data">
          <div class="text-nowrap">
            {{ data.item.failedFiles }}
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              variant="outline-success"
              size="sm"
              @click="channelExportations(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <div
        v-if="hasMore"
        class="d-flex justify-content-center align-self-center mt-2"
      >
        <small>{{ $t('downToSeeEl') }}</small><feather-icon
          icon="ArrowDownIcon"
          size="20"
          class="ml-50"
        />
      </div>
    </b-overlay>
    <div ref="infiniteScroll" />
  </section>
</template>

<script>

import {
  BButton,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import {
  messageError,
  isEmpty,
  formateDateTime,
} from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BButton,
    BTable,
    BRow,
    BSpinner,
    BCol,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isEmpty,
      show: false,
      headers: {},
      exportationsInfo: [],
      formateDateTime,
      exportations: [],
      isSuperuser: false,
      lastKey: '',
      hasMore: true,
      isLoading: false,
      fastChannelId: this.$route.params.id != null ? this.$route.params.id : null,
    }
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = getUserData().profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.isSuperuser = getUserData().isSuperuser
    if (this.fastChannelId) this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const element = this.$refs.infiniteScroll
      const rect = element.getBoundingClientRect()
      if (rect.top <= window.innerHeight && this.hasMore && !this.isLoading) {
        this.channelHistory(this.fastChannelId)
      }
    },

    channelHistory(id) {
      this.show = true
      this.isLoading = true
      const query = `{
          allFastChannelBatchExportations(channelId: "${id}",exclusiveStartKey:"${this.lastKey}") {
                 items {
                  id
                  channel {
                    id
                  }
                  user {
                    id
                    username
                  }
                  client {
                    id
                  }
                  createDate
                  filesCount
                  successFiles
                  failedFiles
                }
                lastEvaluatedKey
                limit
              }
            }
        `
      axios
        .post('', { query })
        .then(res => {
          messageError(res, this)
          this.show = false

          const srch = res.data.data.allFastChannelBatchExportations.items
          if (!isEmpty(srch)) {
            this.exportations = [...this.exportations, ...srch]
            this.lastKey = res.data.data.allFastChannelBatchExportations.lastEvaluatedKey
            this.testMore(id)
          } else {
            this.exportations = [...this.exportations, ...srch]
            this.hasMore = false
          }
          this.isLoading = false
        })
        .catch(() => {
          this.show = false
        })
    },
    testMore(id) {
      const query = `{
          allFastChannelBatchExportations(channelId: "${id}",exclusiveStartKey:"${this.lastKey}") {
                 items {
                  id
                  channel {
                    id
                  }
                  user {
                    id
                    username
                  }
                  client {
                    id
                  }
                  createDate
                  filesCount
                  successFiles
                  failedFiles
                }
                lastEvaluatedKey
                limit
              }
            }
        `
      axios
        .post('', { query })
        .then(res => {
          messageError(res, this)

          const srch = res.data.data.allFastChannelBatchExportations.items
          if (isEmpty(srch)) {
            this.hasMore = false
          }
        })
        .catch(() => {
        })
    },
    goBack() {
      window.history.back()
    },
    channelExportations(data) {
      const { id } = data
      this.$router.push({ name: 'history-exportations', params: { id } })
    },

  },
}
</script>
<style lang="scss" scoped>
.card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

.card-body h4 {
  font-size: 1.286rem !important;
}

.col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.fly-image-input {
  max-width: 100%;
}

.base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.div {
  display: inline-block;
}

.inputColor {
  visibility: hidden;
}

.placeholder_photo {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder_photo:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.cBox {
  align-items: center;
}

.custom-control-label {
  font-size: 15px;
}

.scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

.list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

.contain {
  object-fit: contain;
  cursor: pointer;
}

.image_avatar {
  max-width: initial;
  max-height: max-content;
  height: 100%;
  width: 100%;
}

.editImage {
  position: absolute;
}

.profile-pic:hover .editImage {
  display: block;
}

.profile-pic {
  position: relative;
  display: inline-block;
}

#channelHistory .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#channelHistory .card-body {
  padding: 0rem;
}

#channelHistory .card-body h4 {
  font-size: 1rem !important;
}

#channelHistory .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#channelHistory #button-content {
  padding: 0px;
}

#channelHistory #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#channelHistory #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#channelHistory .acortarParrafo {
  display: block;
  display: -webkit-box;
  line-height: 1.4;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

#channelHistory .wrap {
  white-space: pre-wrap;
}

#channelHistory .body-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

#channelHistory .noBorder {
  border-bottom: 0px;
  width: -webkit-fill-available;
  width: -moz-available;
}

#channelHistory .text-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  text-decoration: underline;
  width: 100px;
  /* Ajusta el ancho según tus necesidades */
  display: block;
  /* Asegura que se comporte como un bloque para que text-overflow funcione */
}
</style>
